import { Controller } from '@hotwired/stimulus';
import { MONTHLY_BY_DAY } from './schedule_frequency_controller';

export default class extends Controller {
  static classes = ['selected', 'deselected'];

  static outlets = ['schedule-frequency'];

  chosenDays = new Set();

  connect() {
    const frequencyValueInput = document.getElementById('modal_every');
    if (frequencyValueInput) {
      frequencyValueInput.addEventListener('change', this.setValuesAndSubmitForm.bind(this));
    }

    this.selectDefaultDay();
  }

  disconnect() {
    const frequencyValueInput = document.getElementById('modal_every');
    if (frequencyValueInput) {
      frequencyValueInput.removeEventListener('change', this.setValuesAndSubmitForm.bind(this));
    }
  }

  scheduleFrequencyOutletConnected(_, element) {
    element.addEventListener('schedule-frequency:scheduleChanged', this.setValuesAndSubmitForm.bind(this));
  }

  scheduleFrequencyOutletDisconnected(_, element) {
    element.removeEventListener('schedule-frequency:scheduleChanged', this.setValuesAndSubmitForm.bind(this));
  }

  toggleDay(event) {
    const day = event.target.dataset.scheduleMonthlyByDayParam;
    if (this.chosenDays.has(day)) {
      this.deselectDay(event, day);
    } else {
      this.selectDay(event, day);
    }
  }

  selectDay(event, day) {
    const { target } = event;
    this.chosenDays.add(day);
    target.classList.remove(...this.deselectedClasses);
    target.classList.add(...this.selectedClasses);
    this.setValuesAndSubmitForm();
  }

  deselectDay(event, day) {
    const { target } = event;
    this.chosenDays.delete(day);
    target.classList.remove(...this.selectedClasses);
    target.classList.add(...this.deselectedClasses);
    this.setValuesAndSubmitForm();
  }

  setValuesAndSubmitForm() {
    const scheduleSummaryForm = document.getElementById('schedule_summary_form');
    if (!scheduleSummaryForm) return;

    const scheduleDataInput = document.getElementById('schedule_summary_schedule');
    if (!scheduleDataInput) return;

    const { interval } = this.scheduleFrequencyOutlet;
    if (interval !== MONTHLY_BY_DAY) return;

    const frequencyValueInput = document.getElementById('modal_every');
    const endRepeatInput = document.getElementById('modal_end_repeat');
    const everyDataInput = document.getElementById('schedule_summary_every');
    const intervalDataInput = document.getElementById('schedule_summary_interval');
    const scheduleForceDateInput = document.getElementById('schedule_summary_force_date');
    const scheduleEndRepeatInput = document.getElementById('schedule_summary_end_repeat');

    everyDataInput.value = frequencyValueInput.value;
    intervalDataInput.value = interval;
    scheduleDataInput.value = Array.from(this.chosenDays).join(',');
    scheduleForceDateInput.value = 'true';
    if (endRepeatInput) { // remove with FEATURE_FLAG_OST_19_SCHEDULE_BUILDER
      scheduleEndRepeatInput.value = endRepeatInput.value;
    }
    scheduleSummaryForm.requestSubmit();
  }

  selectDefaultDay() {
    const preSelectedDay = document.querySelector('.monthly_by_day_default_value');
    if (preSelectedDay) {
      const day = preSelectedDay.getAttribute('data-schedule-monthly-by-day-param');
      this.chosenDays.add(day);
      this.setValuesAndSubmitForm();
    }
  }
}
