import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    changed: { type: Boolean, default: false },
    confirm: String,
  };

  connect() {
    this.boundHandleKeydown = this.handleKeydown.bind(this);
    document.body.addEventListener('keydown', this.boundHandleKeydown);

    this.disableInputsAndButtons();
  }

  disconnect() {
    document.body.removeEventListener('keydown', this.boundHandleKeydown);

    this.enableInputsAndButtons();

    document.querySelectorAll('a').forEach((a) => {
      a.removeAttribute('data-turbo-method');
      a.removeAttribute('data-turbo-confirm');
    });
  }

  handleKeydown(event) {
    // Exclude from submission if the source element has a binded keydown action
    const { srcElement } = event;
    if (
      event.key === 'Enter'
      && !(srcElement.dataset.action && srcElement.dataset.action.includes('keydown'))
    ) {
      event.preventDefault();
      this.element.requestSubmit();
    }
  }

  changedValueChanged() {
    if (!this.changedValue) return;

    document.querySelectorAll('a').forEach((a) => {
      a.setAttribute('data-turbo-method', 'GET');
      a.setAttribute('data-turbo-confirm', this.confirmValue);
    });
  }

  changed() {
    this.changedValue = true;
  }

  refresh() {
    const commitField = document.createElement('input');
    commitField.type = 'hidden';
    commitField.name = 'commit';
    commitField.value = 'refresh';
    this.element.appendChild(commitField);
    this.element.requestSubmit();
  }

  disableInputsAndButtons() {
    this.originalStates = new Map();
    const allInputsAndButtons = this.constructor.getAllInputsAndButtonsOutsideInlineForm();
    allInputsAndButtons.forEach((element) => {
      if (!this.element.contains(element)) {
        const el = element;
        this.originalStates.set(el, this.constructor.getElementDisabledProperty(el));
        this.constructor.toggleDisableOnElement(el, true, this.originalStates);
      }
    });
  }

  enableInputsAndButtons() {
    if (this.originalStates) {
      this.originalStates.forEach((originalDisabled, element) => {
        this.constructor.toggleDisableOnElement(element, originalDisabled, this.originalStates);
      });
      this.originalStates.clear();
    }
  }

  static getAllInputsAndButtonsOutsideInlineForm() {
    return document.querySelectorAll(
      '.bottom-primary input, .bottom-primary button, .bottom-primary div.trix-editor, '
      + '.bottom-primary trix-content, .bottom-primary ul, .bottom-primary h2, .bottom-primary span, '
      + '.body-secondary input, .body-secondary button, .body-secondary trix-editor, '
      + '.body-secondary div.trix-content, .body-secondary ul, .body-secondary h2, .body-secondary span',
    );
  }

  // Trix editor does not support the disabled property,
  // it uses contentEditable instead
  // https://github.com/basecamp/trix/issues/410
  static getElementDisabledProperty(element) {
    if (element.localName === 'trix-editor') {
      return element.contentEditable;
    }
    if (element.localName === 'ul'
      || element.localName === 'h2'
      || element.localName === 'span'
      || element.localName === 'div') {
      // ULs do not support disabled property
      return false;
    }
    return element.disabled;
  }

  static toggleDisableOnElement(element, value, originalStates) {
    const el = element;
    if (el.localName === 'trix-editor') {
      el.contentEditable = !value;
      // Trix stored element reference is not updated by the third party library
      // so we need to update it manually
      originalStates.set(el, !value);
    } else if (el.localName === 'ul'
      || el.localName === 'h2'
      || el.localName === 'span'
      || el.localName === 'div') {
      if (value) {
        el.style.opacity = 0.5;
        el.style.pointerEvents = 'none';
      } else {
        el.style.opacity = 1;
        el.style.pointerEvents = '';
      }
    } else {
      el.style.pointerEvents = value ? 'none' : '';
      el.disabled = value;
    }
  }
}
