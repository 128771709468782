import { Controller } from '@hotwired/stimulus';

export default class ClickController extends Controller {
  static values = { clicked: Boolean };

  disconnect() {
    this.clickedValue = false;
  }

  connect() {
    this.clickedValue = false;
  }

  clickLink(event) {
    if (this.clickedValue === true) {
      event.preventDefault();
    } else {
      this.clickedValue = true;
    }
  }
}
