/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';

const DAILY = 'daily';

export const MONTHLY_BY_DATE = 'monthly_by_date';

export const MONTHLY_BY_DAY = 'monthly_by_day';

const WEEKLY = 'weekly';

const YEARLY = 'yearly';

export default class extends Controller {
  static values = {
    inputPrefix: String, dayOfMonth: String, monthlyByDay: String,
  };

  static targets = ['dailyInterval', 'weeklyInterval', 'monthlyInterval', 'yearlyInterval', 'tooltip'];

  static outlets = ['form-listener', 'tabs'];

  get interval() {
    const { value } = document.getElementById('modal_interval');
    if (value === 'Days') return DAILY;
    if (value === 'Weeks') return WEEKLY;
    if (value === 'Years') return YEARLY;

    return this.tabsOutlet.selectedTabName;
  }

  tabsOutletConnected(_, element) {
    element.addEventListener('tab:select', this.#dispatchScheduleChanged.bind(this));
  }

  tabsOutletDisconnected(_, element) {
    element.removeEventListener('tab:select', this.#dispatchScheduleChanged.bind(this));
  }

  saveModal() {
    this.updateEndRepeatFromModal();
    this.updateEveryFromModal();
    this.updateIntervalFromModal();

    if (this.hasFormListenerOutlet) {
      this.formListenerOutlet.refresh();
    } else {
      document.getElementById('interval_selection').dispatchEvent(new Event('debounced:change'));
    }
  }

  updateEndRepeatFromModal() {
    const modalEndRepeat = document.getElementById('modal_end_repeat');
    const formEndRepeat = document.getElementById(`${this.inputPrefixValue}_end_repeat`);

    if (modalEndRepeat) { // remove check with FEATURE_FLAG_OST_19_SCHEDULE_BUILDER
      formEndRepeat.value = modalEndRepeat.value;
    }
  }

  updateEveryFromModal() {
    const { value } = document.getElementById('modal_every');
    this.updateEvery(value);
  }

  updateIntervalFromModal() {
    this.clearCustomValues();

    const { interval } = this;

    if (interval === WEEKLY) {
      this.updateDaysOfWeekFromModal();
    } else if (interval === MONTHLY_BY_DATE) {
      this.updateDaysOfMonthFromModal();
    } else if (interval === MONTHLY_BY_DAY) {
      this.updateMonthlyByDaysFromModal();
    }

    const formInterval = document.getElementById(`${this.inputPrefixValue}_interval`);
    formInterval.value = interval;
  }

  clearCustomValues() {
    const formDaysOfWeek = document.getElementById(`${this.inputPrefixValue}_days_of_week`);
    formDaysOfWeek.innerHTML = '';

    const formDaysOfMonth = document.getElementById(`${this.inputPrefixValue}_days_of_month`);
    formDaysOfMonth.innerHTML = '';

    const formMonthlyByDays = document.getElementById(`${this.inputPrefixValue}_monthly_by_days`);
    formMonthlyByDays.innerHTML = '';
  }

  updateDaysOfWeekFromModal() {
    const modalDaysOfWeek = document.getElementById('schedule_summary_schedule');
    const formDaysOfWeek = document.getElementById(`${this.inputPrefixValue}_days_of_week`);
    this.addInputFields(modalDaysOfWeek.value, `${this.inputPrefixValue}[days_of_week][]`, formDaysOfWeek);
  }

  updateDaysOfMonthFromModal() {
    const modalDaysOfMonth = document.getElementById('schedule_summary_schedule');
    const formDaysOfMonth = document.getElementById(`${this.inputPrefixValue}_days_of_month`);
    this.addInputFields(modalDaysOfMonth.value, `${this.inputPrefixValue}[days_of_month][]`, formDaysOfMonth);
  }

  updateMonthlyByDaysFromModal() {
    const modalMonthlyByDays = document.getElementById('schedule_summary_schedule');
    const formMonthlyByDays = document.getElementById(`${this.inputPrefixValue}_monthly_by_days`);
    this.addInputFields(modalMonthlyByDays.value, `${this.inputPrefixValue}[monthly_by_days][]`, formMonthlyByDays);
  }

  addInputFields(values, name, parentElement) {
    const parent = parentElement;
    let elements = '';
    if (values) {
      values.split(',').forEach((value) => {
        elements = elements.concat(`<input name="${name}" type="hidden" value="${value.trim()}">`);
      });
    }
    parent.innerHTML = elements;
  }

  toggleInterval(interval) {
    if (interval === 'Days') {
      this.setEveryInputDisabled(true);
      this.dailyIntervalTarget.classList.remove('hidden');
      this.tooltipTarget.classList.remove('hidden');
      this.monthlyIntervalTarget.classList.add('hidden');
      this.weeklyIntervalTarget.classList.add('hidden');
      this.yearlyIntervalTarget.classList.add('hidden');
    } else if (interval === 'Months') {
      this.setEveryInputDisabled(false);
      this.dailyIntervalTarget.classList.add('hidden');
      this.tooltipTarget.classList.add('hidden');
      this.monthlyIntervalTarget.classList.remove('hidden');
      this.weeklyIntervalTarget.classList.add('hidden');
      this.yearlyIntervalTarget.classList.add('hidden');
    } else if (interval === 'Weeks') {
      this.setEveryInputDisabled(false);
      this.dailyIntervalTarget.classList.add('hidden');
      this.tooltipTarget.classList.add('hidden');
      this.monthlyIntervalTarget.classList.add('hidden');
      this.weeklyIntervalTarget.classList.remove('hidden');
      this.yearlyIntervalTarget.classList.add('hidden');
    } else if (interval === 'Years') {
      this.setEveryInputDisabled(true);
      this.dailyIntervalTarget.classList.add('hidden');
      this.tooltipTarget.classList.remove('hidden');
      this.monthlyIntervalTarget.classList.add('hidden');
      this.weeklyIntervalTarget.classList.add('hidden');
      this.yearlyIntervalTarget.classList.remove('hidden');
    }
  }

  updateFrequency(event) {
    const selectedValue = event.target.value;

    if (selectedValue === 'custom') {
      this.dispatch('customFrequencySelected', { detail: { content: selectedValue } });
    } else {
      this.clearCustomValues();
      this.updateEvery(1);

      if (selectedValue === 'monthly_by_day') {
        const formMonthlyByDays = document.getElementById(`${this.inputPrefixValue}_monthly_by_days`);
        this.addInputFields(this.monthlyByDayValue, `${this.inputPrefixValue}[monthly_by_days][]`, formMonthlyByDays);
      } else if (selectedValue === 'monthly_by_date') {
        const formDaysOfMonth = document.getElementById(`${this.inputPrefixValue}_days_of_month`);
        this.addInputFields(this.dayOfMonthValue, `${this.inputPrefixValue}[days_of_month][]`, formDaysOfMonth);
      }

      const formIntervalSelection = document.getElementById('interval_selection');
      const formInterval = document.getElementById(`${this.inputPrefixValue}_interval`);
      formInterval.value = formIntervalSelection.value;

      if (this.hasFormListenerOutlet) {
        this.formListenerOutlet.refresh();
      }
    }
  }

  updateEvery(value) {
    const formEvery = document.getElementById(`${this.inputPrefixValue}_every`);
    formEvery.value = value;
  }

  updateInterval(event) {
    this.toggleInterval(event.target.value);
    this.#dispatchScheduleChanged();
  }

  changeEndRepeat() {
    this.#dispatchScheduleChanged();
  }

  restoreOriginalFrequency() {
    const originalFrequencyElement = document.getElementById(`${this.inputPrefixValue}_interval`);
    const formInterval = document.querySelector('[label_text="Frequency"]');
    if (formInterval) {
      formInterval.value = originalFrequencyElement.value;
    }
  }

  setEveryInputDisabled(disabled) {
    const everyInput = document.getElementById('modal_every');
    everyInput.disabled = disabled;
    if (disabled) {
      everyInput.value = 1;
    }
  }

  #dispatchScheduleChanged() {
    this.element.dispatchEvent(new Event('schedule-frequency:scheduleChanged'));
  }

  toggleIndefinitely(event) {
    const modalEndRepeatContainer = document.getElementById('modal_end_repeat_container');
    const modalEndRepeat = document.getElementById('modal_end_repeat');
    if (modalEndRepeatContainer) { // remove check with FEATURE_FLAG_OST_19_SCHEDULE_BUILDER
      modalEndRepeatContainer.classList.toggle('hidden', event.target.checked);
      document.querySelector('label[for="modal_end_repeat"]').classList.toggle('opacity-50');
      modalEndRepeat.value = '';
      modalEndRepeat.dispatchEvent(new Event('change'));
    }
  }
}
