import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static outlets = ['schedule-frequency'];

  connect() {
    const intervalSelect = document.getElementById('modal_interval');
    if (intervalSelect) {
      intervalSelect.addEventListener('change', this.constructor.submitForm.bind(this));
    }
  }

  disconnect() {
    const intervalSelect = document.getElementById('modal_interval');
    if (intervalSelect) {
      intervalSelect.removeEventListener('change', this.constructor.submitForm.bind(this));
    }
  }

  scheduleFrequencyOutletConnected(_, element) {
    element.addEventListener('schedule-frequency:scheduleChanged', this.constructor.submitForm.bind(this));
  }

  scheduleFrequencyOutletDisconnected(_, element) {
    element.removeEventListener('schedule-frequency:scheduleChanged', this.constructor.submitForm.bind(this));
  }

  static submitForm() {
    const scheduleSummaryForm = document.getElementById('schedule_summary_form');
    const frequencyValueInput = document.getElementById('modal_every');
    const endRepeatInput = document.getElementById('modal_end_repeat');
    const everyDataInput = document.getElementById('schedule_summary_every');
    const intervalDataInput = document.getElementById('schedule_summary_interval');
    const scheduleDataInput = document.getElementById('schedule_summary_schedule');
    const scheduleForceDateInput = document.getElementById('schedule_summary_force_date');
    const scheduleEndRepeatInput = document.getElementById('schedule_summary_end_repeat');
    const yearlyIntervalElement = document.querySelector('[data-schedule-frequency-target="yearlyInterval"]');
    const isYearlyIntervalVisible = yearlyIntervalElement && !yearlyIntervalElement.classList.contains('hidden');

    if (scheduleSummaryForm && scheduleDataInput && isYearlyIntervalVisible) {
      everyDataInput.value = frequencyValueInput.value;
      intervalDataInput.value = 'yearly';
      scheduleDataInput.value = frequencyValueInput.value;
      scheduleForceDateInput.value = 'false';
      if (endRepeatInput) { // remove with FEATURE_FLAG_OST_19_SCHEDULE_BUILDER
        scheduleEndRepeatInput.value = endRepeatInput.value;
      }
      scheduleSummaryForm.requestSubmit();
    }
  }
}
