import { Controller } from '@hotwired/stimulus';
import { MONTHLY_BY_DATE } from './schedule_frequency_controller';

export default class extends Controller {
  static classes = ['selected', 'deselected'];

  static outlets = ['schedule-frequency'];

  chosenDates = new Set();

  connect() {
    const frequencyValueInput = document.getElementById('modal_every');
    if (frequencyValueInput) {
      frequencyValueInput.addEventListener('change', this.setValuesAndSubmitForm.bind(this));
    }

    this.selectDefaultDate();
  }

  disconnect() {
    const frequencyValueInput = document.getElementById('modal_every');
    if (frequencyValueInput) {
      frequencyValueInput.removeEventListener('change', this.setValuesAndSubmitForm.bind(this));
    }
  }

  scheduleFrequencyOutletConnected(_, element) {
    element.addEventListener('schedule-frequency:scheduleChanged', this.setValuesAndSubmitForm.bind(this));
  }

  scheduleFrequencyOutletDisconnected(_, element) {
    element.removeEventListener('schedule-frequency:scheduleChanged', this.setValuesAndSubmitForm.bind(this));
  }

  toggleDate(event) {
    if (this.chosenDates.has(event.params.date)) {
      this.deselectDate(event);
    } else {
      this.selectDate(event);
    }
  }

  selectDate(event) {
    const { target, params: { date } } = event;
    this.chosenDates.add(date);
    target.classList.remove(...this.deselectedClasses);
    target.classList.add(...this.selectedClasses);
    this.setValuesAndSubmitForm();
  }

  deselectDate(event) {
    const { target, params: { date } } = event;
    this.chosenDates.delete(date);
    target.classList.remove(...this.selectedClasses);
    target.classList.add(...this.deselectedClasses);
    this.setValuesAndSubmitForm();
  }

  setValuesAndSubmitForm() {
    const scheduleSummaryForm = document.getElementById('schedule_summary_form');
    if (!scheduleSummaryForm) return;

    const scheduleDataInput = document.getElementById('schedule_summary_schedule');
    if (!scheduleDataInput) return;

    const { interval } = this.scheduleFrequencyOutlet;
    if (interval !== MONTHLY_BY_DATE) return;

    const frequencyValueInput = document.getElementById('modal_every');
    const endRepeatInput = document.getElementById('modal_end_repeat');
    const everyDataInput = document.getElementById('schedule_summary_every');
    const intervalDataInput = document.getElementById('schedule_summary_interval');
    const scheduleForceDateInput = document.getElementById('schedule_summary_force_date');
    const scheduleEndRepeatInput = document.getElementById('schedule_summary_end_repeat');

    everyDataInput.value = frequencyValueInput.value;
    intervalDataInput.value = interval;
    scheduleDataInput.value = Array.from(this.chosenDates).join(',');
    scheduleForceDateInput.value = 'true';
    if (endRepeatInput) { // remove with FEATURE_FLAG_OST_19_SCHEDULE_BUILDER
      scheduleEndRepeatInput.value = endRepeatInput.value;
    }
    scheduleSummaryForm.requestSubmit();
  }

  selectDefaultDate() {
    const preSelectedDate = document.getElementsByClassName('selected-day')[0];
    if (preSelectedDate) {
      const day = preSelectedDate.getAttribute('data-monthly-by-date-date-param');
      this.chosenDates.add(day);
      this.setValuesAndSubmitForm();
    }
  }
}
