/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { inputPrefix: String };

  static targets = ['reloadForm', 'startDate'];

  connect() {
    this.element.addEventListener(
      'schedule-frequency:customFrequencySelected',
      this.openCustomFrequencyModal.bind(this),
    );

    this.element.addEventListener(
      'modal:cancel',
      this.restoreOriginalFrequency.bind(this),
    );
  }

  openCustomFrequencyModal() {
    this.dispatch('openModalViaListener', { detail: { modalIdentifier: 'custom-frequency-modal' } });
  }

  restoreOriginalFrequency() {
    const originalFrequencyElement = document.getElementById(`${this.inputPrefixValue}_interval`);
    const formInterval = document.querySelector('[label_text="Frequency"]');
    if (formInterval) {
      formInterval.value = originalFrequencyElement.value;
    }
  }

  setStartDate({ target: { value } }) {
    if (!this.hasReloadFormTarget) return;

    this.startDateTarget.value = value;
    this.reloadFormTarget.requestSubmit();
  }
}
